import { Box, Link } from '@mui/material';
import { useCallback } from 'react';
import { useLoginMutation } from '../../../plugins/gatsby-plugin-redux/store/api/auth.api';
import ErrorAlert from '../../components/app/ErrorAlert';
import LoadingButton from '../../components/app/LoadingButton';
import AuthLayout from '../../components/pages/auth/AuthLayout';
import Form from '../../field/Form';
import InputField from '../../field/InputField';
import InputPasswordField from '../../field/InputPasswordField';
import handleSubmitAction from '../../helpers/handleSubmitAction';
import { isRequired } from '../../helpers/validators';
import useAuthRedirect from '../../hooks/useAuthRedirect';
import { SubmitHandler } from '../../types/app';
import { LoginFormData } from '../../types/form';

const Signin = () => {
  useAuthRedirect();

  const [loginAction] = useLoginMutation();

  const handleLogin = useCallback<SubmitHandler<LoginFormData>>(async (values) => {
    await loginAction(values).unwrap();
  }, [loginAction]);

  return (
    <AuthLayout>
      <Form<LoginFormData>
        initialValues={{ identifier: '', password: '' }}
        onSubmit={handleSubmitAction(handleLogin)}
      >
        {(config) => (
          <form onSubmit={config.handleSubmit}>
            <ErrorAlert message={config.errors._server?.message} />
            <InputField name="identifier" fullWidth label="Username/Email" autoComplete="identifier" rules={[isRequired]} />
            <InputPasswordField
              noMargingBottom
              name="password"
              type="password"
              fullWidth
              label="Password"
              autoComplete="password"
              rules={[isRequired]}
            />
            <Box textAlign="right" marginY={1}>
              <Link href="/auth/forgot-password">Forgot password?</Link>
            </Box>
            <LoadingButton type="submit" fullWidth loading={config.isLoading}>Login</LoadingButton>

            <Box textAlign="center" marginY={2}>
              <Link href="/auth/signup">{'Don\'t have an account? Create a free Litireso account'}</Link>
            </Box>

          </form>
        )}
      </Form>
    </AuthLayout>
  );
};

export default Signin;
